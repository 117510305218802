import { QueryClient } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { NextPageWithLayout } from '../components/layouts';
import LandingPageLayout from '../components/layouts/LandingPageLayout';
import useRoutePrefetch from '../hooks/useRoutePrefetch';
import CoreAPIService from '../services/CoreAPIService';
import UserTenantsService from '../services/UserTenantsService';
import { detectAndBestEffortSetLanguage } from '../utils/localisationUtils';
import { dehydrateReactQueryPrefetchedInfiniteQuery } from '../utils/react-query-utils';
import LandingPageHome from './LandingPageHome';

const Home: NextPageWithLayout = () => {
  useRoutePrefetch('/[tenantId]/lessons');
  useRoutePrefetch('/[tenantId]/workspace/[workspaceId]');

  return (
    <Container>
      <LandingPageLayout hideBlobs={true} disableHeadline={true}>
        <LandingPageHome />
      </LandingPageLayout>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
`;

const collectionId =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    ? ['187160841829']
    : process.env.NEXT_PUBLIC_APP_ENV === 'dev' ||
      process.env.NEXT_PUBLIC_APP_ENV === 'pr'
    ? ['213628462213']
    : ['1039669330955']; // Last is local - change according to your needs

const TIMEOUT = 20000;
export const getServerSideProps: GetServerSideProps = async context => {
  try {
    const sessionPromise = getSession({ req: context.req });
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Timeout')), TIMEOUT),
    );

    const session = (await Promise.race([
      sessionPromise,
      timeoutPromise,
    ])) as Session | null;

    if (session) {
      const { activeTenantId } = await UserTenantsService.get(session.user.uid);

      return {
        redirect: {
          permanent: false,
          destination: `/${activeTenantId}/home`,
        },
      };
    } else {
      context.res.setHeader(
        'Cache-Control',
        'public, s-maxage=3600, stale-while-revalidate=1209600',
      );
      const queryClient = new QueryClient();

      await queryClient.prefetchQuery(
        ['front-page-collections'],
        async () => CoreAPIService.fetchMultipleCollections(collectionId),
        {
          staleTime: Infinity,
        },
      );

      return {
        props: {
          dehydratedState: dehydrateReactQueryPrefetchedInfiniteQuery(queryClient),
          ...(await detectAndBestEffortSetLanguage(context)),
        },
      };
    }
  } catch (error) {
    return {
      props: {},
    };
  }
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <>{page}</>;
};
export default Home;
