import { PrefetchOptions } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

function useRoutePrefetch(
  path: string,
  asPath?: string | undefined,
  options?: PrefetchOptions | undefined,
) {
  const router = useRouter();
  useEffect(() => {
    router.prefetch(path, asPath, options);
  }, [path, asPath, options, router]);
}

export default useRoutePrefetch;
